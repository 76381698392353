@media only screen and (min-width: 1280px) and (max-width: 1400px){

    .links{
        margin-left: 40px;
    }
    .text-img-slider{
        position: relative !important;
        left: 80px !important;
    }
    .banner-btn{
        padding:8px 5px 10px 5px;
    }


    .counter-section{
        margin-right: 10px !important;
    }
    .counter-section h2{
        margin-right: 20px !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px){

    /*
    #navbar-logo{
        left: 100px !important;
    }*/
    .links{
        margin-left: 40px !important;
    }

    .right{
        right: 70px !important;
    }

    .banner-btn{
        width: 20% !important;
        left: 40% !important;
    }

    .banner-btn{
        padding: 16px 5px 10px 5px;
    }


    .sldier-carousel{
        text-align: center;
      }
      .text-img-slider{
        position: relative !important;
        left: 80px !important;
      }
}
@media only screen and (min-width: 992px) and (max-width: 1200px){
    #navbar-logo{
        top: -25px !important;
    }
    .right{
        left: -50px !important;
    }


    .counter-section h6{
        margin-left: 160px !important;
    }
}


/*    Device = Laptops, Desktops
---------------------------------------------------------------- */
@media only screen and (min-width: 992px) and (max-width: 1280px){
                
    /* Navigation */

    #navbar-logo{
        left: 130px;
    }
    .links{
        margin-left: 0;
    }
    .right{
        right: -70px;
    }
    
    
                /* Header */
                #header-container{
                    width: 100%;
                }
                .text-container{
                    width: 100%;
                }
                .inner-container h2{
                    font-size: 40px;
                }
                .banner-btn{
                    width: 20%;
                    left: 41%;
                }

                /* Services section */
                #sevices-container{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                .about-inner-div{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                .left-div{
                    width: 48%;
                }
                .right-div{
                    width: 48%;
                }
                .left-div img{
                    width: 100%;
                }
                .right-div img{
                    width: 100%;
                }


                /* Our-games section */
                #Our-games{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                .ourgames-innerdiv{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                /* game 1 */
                #games-section1{
                    width: 100%;
                    height: 100vh;
                    padding-top: 0;
                    padding-bottom: 0;
                }

                .full-img-slider1{
                    height: 100vh;
                    background-position: left center;
                }
                .full-img-slider2{
                    height: 100vh;
                }
                .full-img-slider3{
                    height: 100vh;
                }
                .full-img-slider4{
                    height: 100vh;
                    background-position: right center;
                }
                .full-img-slider5{
                    height: 100vh;
                    background-position: right center;
                }

                .text{
                    width: 50%;
                }
                .text-btn{
                    width: 45%;
                }

                /* game 2 */
                #Two-section{
                    width: 100%;
                    height: auto;
                  }
                  .Two-section-container{
                    width: 100%;
                  }
                .inline{
                    margin-bottom: 20px;
                }
                /* Game 3 */
                #games-section3{
                    width: 100%;
                    height: auto;
                }

                /* customer section */
                .sldier-carousel{
                    text-align: center;
                  }
                  .text-img-slider{
                    position: relative;
                    left: -50px;
                  }

                /* Counter */
                #counter-section{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                .counter-section{
                    width: 20%;
                    vertical-align: top;
                    position: relative;
                    left: 7%;
                }

                .counter-section h6{
                    margin-left: 180px;
                }
                
}


/*    Tablet
---------------------------------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px){
    /* Navigation */
    .mobile{
        display: none !important;
    }
    .left{
        left: 0;
    }
    .right{
        right: 0;
      }

    .navbar-collapse{
        background-color: #17161a !important;
        height: 250px;
    }
            /* Header */
            #header-container{
                width: 100%;
            }
            .text-container{
                width: 100%;
            }
            .inner-container h2{
                font-size: 40px;
            }
            .banner-btn{
                width: 30%;
                left: 35%;
            }

            /* About section */
            #about-section{
                width: 100%;
                padding-left: 5px;
                padding-right: 5px;
            }
            .about-innerdiv{
                width: 100%;
                padding-left: 5px;
                padding-right: 5px;
            }
            .about-text{
                padding-left: 10px;
                padding-right: 10px;
            }

            .about-heading{
                text-align: center;
              }
              .about-container{
                width: 100%;
              }
              .left-div{
                width: 100%;
                float: none !important;
              }
              .text-left-container{
                width: 100%;
              }
              .text-left-container p{
                text-align: center;
              }
              .right-div{
                width: 100%;
                float: none !important;
              }
              .image-container{
                padding-top: 20px;
              }
              .image-container img{
                width: 60% !important;
              } 

            /* Services section */
            #sevices-container{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            .about-inner-div{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            .services{
                width: 100%;
            }
            .left-div{
                width: 100%;
                float: none;
            }
            .left-div img{
                width: 100%;
            }
            .right-div{
                width: 100%;
                float: none;
            }
            .right-div img{
                width: 100%;
            }

            /* Our-games section */
            #Our-games{
                width: 100%;
                padding-left: 5px;
                padding-right: 5px;
            }
            .ourgames-innerdiv{
                width: 100%;
                padding-left: 5px;
                padding-right: 5px;
            }
            .ourgames-heading{
                width: 100%;
            }
            .ourgames-text{
                padding-left: 5px;
                padding-right: 5px;
            }

            /* game 1 */
            #games-section1{
                width: 100%;
                height: auto;
                padding-top: 0;
                padding-bottom: 0;
            }
            .text-container{
                width: 100%;
            }
            .full-img-slider1{
                height: 80vh;
                background-position: left center;
            }
            .full-img-slider2{
                height: 80vh;
            }
            .full-img-slider3{
                height: 80vh;
            }
            .full-img-slider4{
                height: 80vh;
                background-position: right center;
            }

            .full-img-slider5{
                height: 100vh;
                background-position: right center;
            }
            .slider-logo img{
                width: 30%;
              }
              .Two-section-text5{
                width: 100%;
                float: none !important;
                left: 0;
              }
              .Two-sectiontext-heading5{
                text-align: center;
              }
              .inline5 img{
                display: block;
              }


            .text{
                width: 100%;
                float: none;
                margin-top: 70px;
                padding-left: 20px;
                padding-right: 20px;
                right: 0;
                top: 10px;
            }
            .Two-section-text{
                width: 100%;
                margin-top: 70px;
            }
            .text-btn{
                width: 30%;
            }
            .slick-prev:before{
                display: none;
              }
              .slick-next:before{
                display: none;
              }

            /* game 2 */
            #Two-section{
                width: 100%;
                height: auto;
                padding-top: 50px;
                padding-bottom: 50px;
            }
            .Two-section-container{
                width: 100%;
            }
            .Two-section-text{
                width: 100%;
                float: none;
                padding-left: 20px;
                padding-right: 20px;
                top: 10px;
            }
            .inline{
                display: block;
                margin-bottom: 20px;
            }

            /* Game 3 */
            #games-section3{
                width: 100%;
                height: auto;
                padding-top: 50px;
                padding-bottom: 50px;
            }

            /* Game 4 */
            #Four-section{
                width: 100%;
                height: auto;
                padding-top: 50px;
                padding-bottom: 50px;
            }

            /* Our customers */
            #customers-section{
                width: 100%;
            }
            .customers-innerdiv{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .customer-text{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .sldier-carousel{
                    text-align: center;
                  }
                  .text-img-slider{
                    position: relative;
                    left: 60px;
                  }

            /* Counter */
            #counter-section{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .counter-section{
                width: 100%;
                display: block;
                text-align: center;
            }
            .counter-section h2{
                text-align: center;
            }

            .counter-section h6{
                margin-left: 420px;
            }

            /* Client section */
            #client-section{
                width: 100%;
            }
            .inner-client-section{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .client-heading{
                width: 100%;
            }
            .sldier-carousel{
                width: 100%;
            }
            .client-slider{
                text-align: center;
            }
            .client-slider img{
                text-align: center;
                left: 0;
                margin-left: 39%;
            }


            /* Work section */
            #works-section{
                width: 100%;
            }
            .inner-work-section{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .work-heading{
                width: 100%;
            }
            .lower-container{
                width: 100%;
            }
            .inline-img{
                display: block;
                margin-right: 0;
                margin-bottom: 50px;
                left: 0;
            }

            /* Contact */
            #contact{
                width: 100%;
            }
            .inner-contact-container{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .left-container{
                padding-left: 10px;
                padding-right: 10px;
            }
            .right-container{
                padding-left: 10px;
                padding-right: 10px;
            }
            .submit-btn{
                border: none !important;
            }


            /* Footer section */
            #footer-section{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .inner-footer{
                width: 100%;
                padding-left: 10px;
                padding-right: 10px;
            }
            .inline-container{
                width: 50%;
                vertical-align: top;
                margin-bottom: 30px;
            }
}


/*		Mobile Layout: 320px.
---------------------------------------------------------------- */
@media only screen and (max-width: 767px){
        /* Navigation */
        .mobile{
            display: none  !important;
        }
        .left{
            left: 0;
        }
        .right{
            right: 0;
          }
        .navbar-collapse{
            background-color: #17161a !important;
            height: 250px;
        }
        /* Header */
        #header-container{
            width: 100%;
        }
        .text-container{
            width: 100%;
        }
        .inner-container h2{
            font-size: 40px;
        }
        .banner-btn{
            width: 50%;
            left: 25%;
        }
        .banner-btn p{
            padding: 0px 20px 20px 20px;
        }

        /* About section */
        #about-section{
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 30px;
          }
          .about-innerdiv{
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
          }
          .about-text{
            padding-left: 10px;
            padding-right: 10px;
          }

          .about-heading{
            text-align: center;
          }
          .about-container{
            width: 100%;
          }
          .left-div{
            width: 100%;
            float: none !important;
          }
          .text-left-container{
            width: 100%;
          }
          .text-left-container p{
            text-align: center;
          }
          .right-div{
            width: 100%;
            float: none !important;
          }
          .image-container{
            padding-top: 20px;
          }

          /* Services section */
        #sevices-container{
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-top: 10px;
        }
        .about-inner-div{
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
        .tabs{
            width: 100% !important;
        }
        .tabs-images .heading{
            line-height: 1.0;
        }
          .tabs button{
            /*width: 25% !important;*/
            width: 100% !important;
            padding-left: 5px !important;
            padding-right: 5px !important;
            word-wrap: break-word !important;
        }
        .tabs-images{
            text-align: center;
          }
          .tabs-images img{
            text-align: center;
            width: 100%;
          }
          .tabs-images img{
            left: 0;
          }

        /* Our-games section */
        #Our-games{
            width: 100%;
            padding-top: 10px;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 20px;
        }
        .ourgames-innerdiv{
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
        }
        .ourgames-heading{
            width: 100%;
        }
        .ourgames-text{
            padding-left: 5px;
            padding-right: 5px;
        }

        /* game 1 */
        #games-section1{
            width: 100%;
            height: auto;
            padding-top: 0;
            padding-bottom: 0;
        }
        .text-container{
            width: 100%;
        }
        .full-img-slider1{
            height: 100vh;
            background-position: -110px center;
        }
        .full-img-slider2{
            height: 100vh;
        }
        .full-img-slider3{
            height: 100vh;
            background-position: -50px center;
        }
        .full-img-slider4{
            height: 100vh;
            background-position: right center;
        }
        .full-img-slider5{
            height: 100vh;
            background-position: right center;
        }
        .slider-logo img{
            width: 30%;
          }
          .Two-section-text5{
            width: 100%;
            float: none !important;
            left: 0;
          }
          .Two-sectiontext-heading5{
            text-align: center;
          }
          .inline5 img{
            display: block;
          }

        .text{
            width: 100%;
            float: none;
            margin-top: 70px;
            padding-left: 20px;
            padding-right: 20px;
            right: 0;
            top: 10px;
        }
        .Two-section-text{
            width: 100%;
            margin-top: 70px;
        }
        .text-btn{
            width: 50%;
        }

        .slick-prev:before{
            display: none;
          }
          .slick-next:before{
            display: none;
          }

        /* game 2 */
        #Two-section{
            width: 100%;
            height: auto;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .Two-section-container{
            width: 100%;
        }
        .Two-section-text{
            width: 100%;
            float: none;
            padding-left: 20px;
            padding-right: 20px;
            top: 10px;
        }
        .inline{
            display: block;
            margin-bottom: 20px;
        }

        /* Game 3 */
        #games-section3{
            width: 100%;
            height: auto;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        /* Game 4 */
        #Four-section{
            width: 100%;
            height: auto;
            padding-top: 50px;
            padding-bottom: 50px;
        }


        /* Our customers */
        #customers-section{
            width: 100%;
        }
        .customers-innerdiv{
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
        }
        .customer-text{
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
        }

        .sldier-carousel{
            text-align: center;
          }
          .text-img-slider{
            left: 20px;
          }
          .text-img-slider img{
            width: 80px;
          }

        /* Counter */
        #counter-section{
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
        }
        .counter-section{
            width: 100%;
            display: block !important;
        }
        .counter-section h2{
            text-align: center;
        }
        .counter-section h6{
            margin-left: 320px;
        }

        /* Client section */
        #client-section{
            width: 100%;
        }
        .inner-client-section{
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
        .sldier-carousel{
            width: 100%;
        }
        .client-slider {
            text-align: center;
            width: 100%;
        }
        .client-slider img{
            left: 0;
            margin-left: 28%;
        }

        /* Work section */
        #works-section{
            width: 100%;
        }
        .inner-work-section{
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
        .lower-container{
            width: 100%;
        }
        .inline-img{
            display: block;
            margin-right: 20px;
            margin-bottom: 50px;
            left: 10px;
        }

        /* Contact */
        #contact{
            width: 100%;
        }
        .inner-contact-container{
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;
        }
        .left-container{
            width: 100%;
            float: none;
            padding-left: 10px;
            padding-right: 10px;
        }
        .right-container{
            width: 100%;
            float: none;
            padding-left: 10px;
            padding-right: 10px;
        }
        .contact-text{
            width: 100%;
        }
        form{
            width: 100% !important;
        }
        .contactForm {
            width: 100% !important;
        }
        .submit-btn{
            border: none !important;
        }
        .companies-inline{
            display: block;
            padding: 0;
        }

        /* Footer section */
        #footer-section{
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
        .inner-footer{
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
        .inline-container{
            width: 100%;
            display: block;
            margin-bottom: 30px;
        }

        /* Credits section */
        #credits-section{
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
        .credit-text{
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }
}

@media only screen and (min-width: 460px) and (max-width: 500px){
    .counter-section h6{
        margin-left: 310px !important;
    }
}
@media only screen and (min-width: 420px) and (max-width: 460px){
    .counter-section h6{
        margin-left: 280px !important;
    }
}
@media only screen and (min-width: 380px) and (max-width: 420px){
    .counter-section h6{
        margin-left: 260px !important;
    }
}
@media only screen and (min-width: 360px) and (max-width: 380px){
    .counter-section h6{
        margin-left: 235px !important;
    }
}

@media only screen and (min-width: 420px) and (max-width: 500px){
    .client-slider img{
        left: 0;
        margin-left: 32%;
    }

    .tabs-images .client-slider img{
        text-align: center;
        left: -25%;
        width: 80%;
      }

      .banner-btn p{
        padding: 5px 20px 20px 20px;
    }
}
@media only screen and (min-width: 501px) and (max-width: 600px){
    .client-slider img{
        left: 0;
        margin-left: 35%;
    }

    .text-img-slider{
        left: -30px;
      }

      .tabs-images .client-slider img{
        text-align: center;
        left: -25%;
        width: 80%;
      }

      .banner-btn p{
        padding: 7px 20px 20px 20px;
    }
}
@media only screen and (min-width: 601px) and (max-width: 767px){
    .client-slider img{
        left: 0;
        margin-left: 35%;
    }

    .text-img-slider{
        left: -30px;
      }

      .tabs-images .client-slider img{
        text-align: center;
        left: -25%;
        width: 80%;
      }

      .banner-btn p{
        padding: 10px 20px 20px 20px;
    }
}