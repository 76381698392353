.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

/* Font css */
@font-face {
	font-family: AGENCYB;
	src: url("../src/fonts/AGENCYB.TTF");
}

/* hightlight text selection */
::selection {
  background: #f3b113; 
}

/****** Css ******/
/* custom scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f3b113; 
  border-radius: 5px;
}



.body{
  width: 100%;
  height: auto;
  overflow-x: hidden;
  background-color: #111113;
}
/* Navbar */
.navbarBg{
  /*background-color: transparent !important;*/
  background: url("../src/assets/navbar-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 90px !important;
}
.nav-items{
  color: white !important;
  font-size: 16px !important;
  margin-right: 30px;
  font-weight: bold !important;
  height: 90px !important;
}
.nav-items:hover{
  color: #f3b113 !important;
}
.me-auto{
  height: 40px !important;
}
.navbar-expand-lg .navbar-nav .nav-link{
  height: 40px !important;
}
.links{
  /*position: relative;
  right: -14%;*/
  position: fixed;
  /*left: 16%;*/
  margin-left: 130px;
  top: 30px;
  color: white !important;
  height: 90px !important;
}
.navbar-fixed-top.scrolled {
  background-color: rgba(0,0,0,39) !important;
  transition: background-color 200ms linear;
}
.navbar-fixed-top.scrolled .nav-link {
  color:#555;
}

#navbar-logo{
  width: 30% !important;
  position: relative;
  left: 130px;
  top: -15px;
  transition: 0.2s;
}
.mobile{
  width: 40%;
}
.right{
  position: relative;
  right: 80px;
}
.left{
  position: relative;
  left: 60px;
}

/* Header */
#header-container{
  width: 100%;
  height: 100vh;
  margin: 0px auto;
  background: url("../src/assets/banner_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  /*position: relative;
  overflow: hidden;
  -webkit-animation: animate-background 50s linear infinite;
  animation: animate-background 50s linear infinite;
  animation-timing-function: linear;*/
}
/*
@keyframes animate-background{
  0% {
    background-position: -1220px -1220px;
  }
  100% {
    background-position: 1200px 0px;
  }
}
*/
/*
video{
  width: 100% !important;
  height: 100vh !important;
  background-repeat: no-repeat !important;
	object-fit: cover;

  position: absolute;
  top: 0;
  left: 0;

  border-radius: 800px/55px;
}
*/

.text-container{
  width: 100%;
  text-align: center;
}
.inner-container{
  text-align: center;
}
.inner-container h2{
  color: white;
  position: relative;
  top: 350px;
  font-size: 50px;
  font-weight: bold;
  font-family: AGENCYB;
}
.inner-container span{
  color: #f3b113;
  font-family: AGENCYB;
}
.inner-container h3{
  color: white;
  position: relative;
  top: 310px;
  font-size: 50px;
  font-weight: bold;
  font-family: AGENCYB;
}

.banner-btn{
  width: 15%;
  text-align: center;
  position: relative;
  top: 350px;
  left: 42%;
  background: url("../src/assets/lets_talk_button.png");
  background-repeat: no-repeat;
  background-size: contain;
  /*background-color: red;*/
  padding: 12px 5px 5px 5px;
  /*clip-path: polygon(0 0, 100% 0%, 95% 100%, 5% 100%);*/
  transition: transform .2s;
}
.banner-btn:hover{
  transition: transform .2s;
  transform: scale(1.1);
}
a{
  text-decoration: none !important;
}
.banner-btn p{
  color: white;
  font-weight: bold;
  font-family: AGENCYB;
}

/* About section */
#about-section{
  width: 100%;
  background: url("../src/assets/about_baner.png") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #111113;
  padding: 10px 30px 10px 10px;
}
.about-innerdiv{
  width: 100%;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}
.about-heading{
  width: 100%;
  text-align: left;
  padding-top: 100px;
  padding-bottom: 30px;
}
.about-heading h2{
  color: white;
  font-family: AGENCYB;
  font-size: 60px;
}
.about-container{
  width: 100%;
  padding: 5px;
}
.left-div{
  width: 50%;
  float: left;
  padding: 5px;
}
.text-left-container{
  padding: 5px;
  width: 100%;
}
.text-left-container p{
  color: white;
  text-align: left;
  line-height: 1.8em;
  font-family: AGENCYB;
  font-size: 18px;
}
.right-div{
  width: 50%;
  float: right;
}
.image-container{
  width: 100%;
  padding: 60px 5px 5px 5px;
}
.image-container img{
  width: 80%;
}

/*
.about-text{
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}
.about-text p{
  color: white;
}*/



/* Services section */
#sevices-container{
  width: 100%;
  background-color: #111113;
  /*background: url("../src/assets/our-services-bg.jpg") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;*/
  padding: 10px;
}
.about-inner-div{
  width: 100%;
  padding: 10px 20px 10px 20px;
}
.services-heading{
  width: 100%;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 60px;
}
.services-heading h2{
  color: white;
  font-size: 60px;
  font-family: AGENCYB;
}
.services{
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
.tabs-images{
  text-align: center;
}
.tabs-images .client-slider img{
  text-align: center;
  left: 25%;
}
.tabs-images a{
  text-align: center !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: bold;
  position: relative !important;
  top: 20px !important;
  font-family: AGENCYB;
  font-size: 20px;
}
.tabs-images a p:hover{
  color: #f3b113 !important;
}
.tabs-images p{
  text-align: center;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  font-family: AGENCYB;
  font-size: 20px;
}
.tabs-images .heading{
  font-size: 40px;
  padding-bottom: 10px;
}
/*
.left-div{
  width: 50%;
  float: left;
}
.left-div img{
  width: 100%;
  transition: transform .2s;
  cursor: pointer;
}
.left-div img:hover{
  transition: transform .2s;
  transform: scale(1.1);
}
.left-div p{
  color: white;
  font-weight: bold;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-top: 15px;
}
.left-div p:hover{
  color: red;
  cursor: pointer;
}
.right-div{
  width: 50%;
  float: right;
}
.right-div img{
  width: 100%;
  transition: transform .2s;
  cursor: pointer;
}
.right-div img:hover{
  transition: transform .2s;
  transform: scale(1.1);
}
.right-div p{
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-top: 15px;
}
.right-div p:hover{
  color: red;
  cursor: pointer;
}*/



.tabs {
  display: flex !important;
  justify-content: space-between !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.tabs button {
  background-color: lightgray !important;
  border: none !important;
  color: white !important;
  cursor: pointer !important;
  padding: 25px !important;
  width: 100% !important;
  word-wrap: break-word !important;
  /*background-color: rgba(255, 255, 255, 0.1);*/
  background-color: #212f3d !important;
  transition: all 0.5s ease-out !important;
  z-index: 10 !important;
}

.tabs button:hover {
  color: white !important;
  /*background-color: rgba(255, 255, 255, 0.15);*/
  background-color: #ecc76f !important;
  z-index: 10 !important;
}

.tabs button:disabled {
  /*background: linear-gradient(245.39deg, #FC65ED -35.14%, #0500FF 143.06%);*/
  background-color: #f3b113 !important;
  color: white !important;
}

.title {
  color: white !important;
  font-size: medium !important;
  margin-bottom: 20px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: AGENCYB !important;
  font-size: 18px !important;
}

.content {
  color: white !important;
  padding: 50px 80px !important;
  background-color: #252525 !important;
  font-weight: 300 !important;
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: justify !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  font-family: AGENCYB !important;
  font-size: 18px !important;
}
.content,
button {
  font-size: 15px !important;
  font-weight: medium !important;
  font-family: AGENCYB !important;
  font-size: 18px !important;
}

.tabs-images{
  text-align: center;
}
.tabs-images img{
  text-align: center;
  width: 50%;
  object-fit: contain;
  position: relative;
  left: 25%;

}

@media only screen and (max-width: 960px) {

  .container {
      width: 100% !important;
  }

  .content {
      padding: 50px !important;
  }
}


/* cards services section */
#service{
  width: 100%;
  background-color: #111113;
  padding: 20px 10px 10px 10px;
}
.cards-slider-services{
  width: 100%;
  padding: 10px;
}


/* Our-games section */
#Our-games{
  width: 100%;
  /*background: url("../src/assets/our-games.jpg") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;*/
  background-color: #111113;
  padding: 45px 10px 0 10px;
}
.ourgames-innerdiv{
  width: 100%;
  text-align: center;
  padding: 10px 20px 10px 20px;
}
.ourgames-heading{
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 60px;
}
.ourgames-heading h2{
  color: white;
  font-family: AGENCYB;
  font-size: 60px;
}
.ourgames-text{
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}
.ourgames-text p{
  color: white;
  font-family: AGENCYB;
  font-size: 18px;
}

/* game 1 */
#games-section1{
  width: 100%;
  /*background: url("../src/assets/fishing-go-bg.jpg") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;*/
  height: 100vh;
}
.full-img-slider1{
  width: 100%;
  background: url("../src/assets/fishing-go-bg.jpg") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.full-img-slider2{
  width: 100%;
  background: url("../src/assets/bg-game.jpg") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.full-img-slider3{
  width: 100%;
  background: url("../src/assets/dogeum-bg.png") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.full-img-slider4{
  width: 100%;
  background: url("../src/assets/bg-santa-floki.jpg") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.full-img-slider5{
  width: 100%;
  background: url("../src/assets/bg.png") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.slick-prev{
  left: 60px !important;
  z-index: 1 !important;
}
.slick-next{
  right: 150px !important;
  z-index: 1 !important;
}
.slick-prev:before{
  background: url("../src/assets/l.png") 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent !important;
  font-size: 100px !important;
}
.slick-next:before{
  background: url("../src/assets/r.png") 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent !important;
  font-size: 100px !important;
}
/*
.bgimg1{
  position: absolute;
  width: 100%;
  height: 100vh;
}
.bgimg1 img{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
*/
.text-container{
  width: 100%;
}
.text{
  width: 45%;
  float: right;
  background-color: rgba(20, 18, 21, 0.7);
  padding: 50px 60px 50px 60px;
  position: relative;
  top: 150px;
  border-radius: 10px;
  /*right: 10%;*/
}
.text-heading{
  text-align: left;
}
.text-heading h2{
  color: white;
  font-family: AGENCYB;
}
.text-para{
  text-align: left;
}
.text-para p{
  color: white;
  font-family: AGENCYB;
}
.text-btn{
  width: 170px;
  /*background-color: red;*/
  background: url("../src/assets/submit_button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  /*padding: 20px 0px 4px 0px;*/
  font-weight: bold;
  font-family: AGENCYB;
}
.text-btn p{
  color: black;
  text-align: center;
  padding: 10px 0 10px 0;
}

/* game 2 */
#Two-section{
  width: 100%;
  background: url("../src/assets/bg-game.jpg") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
}
.Two-section-container{
  width: 100%;
}
.Two-section-text{
  width: 45%;
  float: left;
  background-color: rgba(20, 18, 21, 0.7);
  padding: 50px 60px 50px 60px;
  position: relative;
  top: 100px;
  border-radius: 10px;
}
.Two-sectiontext-heading{
  text-align: left;
}
.Two-sectiontext-heading h2{
  color: white;
  font-family: AGENCYB;
}
.Two-sectiontext-para{
  text-align: left;
}
.Two-sectiontext-para p{
  color: white;
  font-family: AGENCYB;
}
.inline{
  display: inline-block;
  margin-right: 20px;
}


/* sldier 1 */
.slider-logo{
  width: 50%;
}
.slider-logo img{
  width: 30%;
}
.Two-section-container5{
  width: 100%;
}
.Two-section-text5{
  width: 45%;
  float: left;
  background-color: rgba(20, 18, 21, 0.55);
  padding: 50px 60px 50px 60px;
  position: relative;
  top: 100px;
  border-radius: 10px;
  margin-top: -50px;
  left: 60px;
}
.Two-sectiontext-heading5{
  text-align: left;
}
.Two-sectiontext-heading5 h2{
  color: white;
  font-family: AGENCYB;
}
.Two-sectiontext-para5{
  text-align: left;
}
.Two-sectiontext-para5 p{
  color: white;
  font-family: AGENCYB;
}
.inline5{
  display: inline-block;
  margin-right: 20px;
}
.inline5 img{
  display: inline-block;
  width: 150px;
}

/* Game 3 */
#games-section3{
  width: 100%;
  background: url("../src/assets/dogeum-bg.jpg") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

/* Game 4 */
#Four-section{
  width: 100%;
  background: url("../src/assets/bg-santa-floki.jpg") 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 80vh;
}


/* Our customers */
#customers-section{
  width: 100%;
  /*background: url("../src/assets/customer-bg.png") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;*/
  background-color: #111113;
}
.customers-innerdiv{
  width: 100%;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}
.customers-heading{
  width: 100%;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 60px;
}
.customers-heading h2{
  color: white;
  font-size: 60px;
  font-family: AGENCYB;
}
.customer-text{
  width: 100%;
  padding: 50px 60px 30px 60px;
}
.customer-text p{
  color: white;
  font-family: AGENCYB;
  font-size: 18px;
}
.sldier-carousel{
  text-align: center;
}
.text-img-slider{
  position: relative;
  left: 100px;
}
.text-img-slider img{
  width: 150px;
}

/* Counter */
#counter-section{
  width: 100%;
  background: url("../src/assets/bg-horor-theme.png") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 40px 30px 40px;
}
.counter-section{
  display: inline-block;
  text-align: center;
  margin-right: 20px;
}
.counter-section span{
  color: white;
  font-weight: bold;
  font-size: 60px;
  text-align: center;
}
.counter-section h2{
  font-size: 20px;
  color: white;
  text-align: center;
  margin-right: 50px;
  position: relative;
  left: 30px;
}

.counter-section h6{
  color: white;
  position: absolute;
  margin-left: 220px;
  margin-top: -75px;
  font-size: 50px;
  font-weight: bold;
}

/* Client section */
#client-section{
  width: 100%;
  background-color: #111113;
  /*background: url("../src/assets/cliennts-bg.png") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;*/

}
.inner-client-section{
  width: 100%;
  text-align: center;
  padding: 40px 50px 10px 50px;
}
.client-heading{
  width: 100%;
  text-align: center;
}
.client-heading h2{
  color: white;
  font-family: AGENCYB;
  font-size: 60px;
}
.sldier-carousel{
  width: 100%;
  text-align: center;
}
.client-slider{
  border-radius: 50%;
  text-align: center;
}
.client-slider img{
  border-radius: 50%;
  text-align: center;
  position: relative;
  left: 44%;
}
.client-slider p, h5, h3{
  color: white;
  padding: 20px 40px 20px 40px;
  font-family: AGENCYB;
}
.client-slider h5{
  font-weight: bold;
  font-family: AGENCYB;
}
.client-slider h3{
  font-weight: bold;
  font-family: AGENCYB;
}

/* Work section */
#works-section{
  width: 100%;
  background-color: #111113;
  /*background: url("../src/assets/our-projects.jpg") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;*/
  padding-top: 20px;
}
.inner-work-section{
  width: 100%;
  text-align: center;
  padding: 30px 50px 30px 50px;
}
.work-heading{
  width: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.work-heading h2{
  color: white;
  text-align: center;
  font-family: AGENCYB;
  font-size: 60px;
}
.lower-container{
  width: 100%;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 30px;
}
.inline-img{
  display: inline-block;
  margin-right: 100px;
  position: relative;
  left: 20px;
}
.inline-img p{
  color: white;
  font-weight: bold;
}
.inline-img p:hover{
  color: #f3b113;
}
.inline-img img{
  transition: transform .2s;
}
.inline-img img:hover{
  transition: transform .2s;
  transform: scale(1.1);
}

/* Contact */
#contact{
  width: 100%;
  background-color: #111113;
  /*background: url("../src/assets/contact-us-bg.jpg") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;*/
}
.inner-contact-container{
  width: 100%;
  text-align: center;
  padding: 70px 50px 30px 50px;
}
.left-container{
  width: 50%;
  float: left;
  padding: 40px 50px 40px 10px;
}
.right-container{
  width: 50%;
  float: right;
  padding: 40px 10px 40px 10px;
}
.contact-heading{
  width: 100%;
}
.contact-heading h2{
  text-align: left;
  color: white;
  font-family: AGENCYB;
}
.contact-text{
  width: 100%;
}
.contact-text p{
  text-align: left;
  color: white;
  font-family: AGENCYB;
  font-size: 18px;
}
.contact-lowerleft-container{
  width: 100%;
}
.left-text {
  text-align: left;
  margin-bottom: 10px;
}
.left-text a{
  color: white;
  text-align: left;
  font-family: AGENCYB;
  font-size: 18px;
}
.left-text a:hover{
  color: #f3b113;
}
.left-text h2{
  padding-top: 20px;
  color: white;
  text-align: left;
  font-family: AGENCYB;
}
.left-text p{
  color: white;
  text-align: left;
  font-family: AGENCYB;
  font-size: 18px;
}
.left-text span{
  color: white;
  font-family: AGENCYB;
  font-size: 18px;
}
.left-text span:hover{
  color: #f3b113;
  cursor: pointer;
  font-size: 18px;
}

.social-container{
  text-align: left;
  margin-top: 20px;
}
.social-container .social{
  margin-right: 20px;
  color: white !important;
}
.social-container .social:hover{
  color: #f3b113 !important;
}


.qr-code{
  width: 100%;
  text-align: center;
}
.qr-image{
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.qr-image img{
  width: 150px;
  padding-top: 5px;
  text-align: center;
}


form{
  width: 100% !important;
}
.contactForm {
  width: 100% !important;
}
#input-field{
  background-color: #222222 !important;
  width: 100% !important;
  color: white !important;
  font-family: AGENCYB;
}
#text-field{
  background-color: #222222 !important;
  width: 100% !important;
  color: white;
  height: 200px !important;
  font-family: AGENCYB;
}
.submit-btn{
  width: 100% !important;
  /*background: red !important;*/
  background: url("../src/assets/submit_button.png");
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none !important;
  border-radius: 18px !important;
}
#contact-btn{
  color: black !important;
}
.contactForm ::placeholder{
  color: white !important;
  font-family: AGENCYB;
}


.companies-container{
  width: 100%;
}
.companies-heading h2{
  text-align: center;
  color: white;
  font-family: AGENCYB;
}
.companies-links{
  width: 100%;
  text-align: center;
}
.companies-inline{
  display: inline-block;
  text-align: center;
  padding: 20px;
}
.companies-inline p{
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-family: AGENCYB;
  font-size: 25px;
}
.companies-inline a{
  color: white !important;
}
.companies-inline a:hover{
  color: #f3b113 !important;
  cursor: pointer;
}

/* Footer section */
#footer-section{
  width: 100%;
  /*background: url("../src/assets/last-links-bg.jpg") 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;*/
  background-color: #111113;
  padding: 30px 40px 30px 40px;
}
.inner-footer{
  width: 100%;
  text-align: center;
  padding: 30px 40px 30px 40px;
}
.inline-container{
  padding-top: 0;
  width: 25%;
  display: inline-block;
  padding: 2px;
  vertical-align:top;
}
.inline-container h4{
  color: white;
  font-weight: bold;
  text-align: left;
  font-family: AGENCYB;
}
.inline-container a{
  text-decoration: none;
  color: white;
  text-align: left;
  font-family: AGENCYB;
  font-size: 18px;
}
.inline-container a:hover{
  color: #f3b113;
  font-size: 18px;
}

/* Credits section */
#credits-section{
  width: 100%;
  background-color: #161616;
  padding: 30px 40px 20px 40px;
}
.credit-text{
  width: 100%;
  text-align: center;
  padding: 30px 40px 20px 40px;
}
.credit-text p{
  color: white;
  text-align: center;
  font-family: AGENCYB;
  font-size: 18px;
}
.credit-text a{
  color: white;
  font-family: AGENCYB;
  font-size: 18px;
}
.credit-text a:hover{
  color: #f3b113;
  font-size: 18px;
}